import React from 'react';
import PortableText from 'react-portable-text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { navigate } from 'gatsby';

import { Grid, Typography, Card, CardContent, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		color: theme.workwaveBlue,
		height: '355px',
		padding: '0px',
		borderRadius: '20px',
		boxShadow: '0 10px 40px 0px rgba(0,0,0,0.15)',
		// maxWidth: '289px',
		position: 'relative',
		paddinBottom: '0px !important',
		// paddingTop: '2.75rem',
		[theme.breakpoints.down('sm')]: {
			height: '315px',
		},
		[theme.breakpoints.down('xs')]: {
			height: '355px',
		},
		// [theme.breakpoints.up('md')]: {
		// 	maxWidth: '287px',
		// },
		// [theme.breakpoints.up('lg')]: {
		// 	maxWidth: '289px',
		// },
		transition: 'transform 0.25s',
		// boxShadow:
		// 	'0 13px 27px -5px rgba(50, 50, 93, 0), 0 8px 16px -8px rgba(0, 0, 0, 0)',
	},
	title: {
		fontSize: '1.4rem',
		fontWeight: 'bold',
		textAlign: 'center',
		margin: '1rem 0 0.5rem 0',
		color: '#002D5C',
	},
	pos: {
		textAlign: 'center',
		color: '#4B5B69',
	},

	button: {
		//padding: '6px 20px',
		opacity: 1,
		//fontSize: '1.1rem',
		marginTop: '-6.5rem',
		background: '#3FA529',
		transition: 'all 0.35s ease !important',
		'&:hover': {
			background: '#3FA529',
			opacity: '0.85 !important',
		},
	},
}));

export const AllIndustryCard = ({ industry, content, templates }) => {
	const classes = useStyles();

	const link = templates
		? `/templates/${industry.slug?.current}`
		: `/industries/${industry.slug?.current}`;
	return (
		<Grid item xs={12} md={6} lg={4}>
			<Card className={classes.root} elevation={0}>
				<CardContent
					style={{
						height: '100%',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'space-between',
						paddingBottom: '16px',

						borderTop: `16px solid ${
							templates ? industry.accentColor[0]?.hexValue : '#3FA529'
						}`,
					}}>
					<Grid item xs={12} lg={11}>
						<Grid container item direction='row' justifyContent='center'>
							<FontAwesomeIcon
								icon={['fad', industry.faIcon]}
								style={{
									color: templates
										? industry.accentColor[0]?.hexValue
										: '#3FA529',
									height: '55px',
									width: '55px',
									border: `1px solid #E5EAF4`,
									borderRadius: '8px',
									background: '#E5EAF4',
									padding: '6px',
									textAlign: 'center',
								}}
							/>
						</Grid>
						{/* This is a band aid for pre localization. will need to change to a new field in sanity eventually. */}
						<Typography variant='body1' className={classes.title}>
							{templates
								? industry.title
								: industry.title
										.replace('Software', '')
										.replace('Business', '')}
						</Typography>
						<PortableText
							content={industry._rawSynopsis || []}
							className={classes.pos}
							serializers={{
								normal: ({ children }) => (
									<Typography variant='body1'>{children}</Typography>
								),
								li: ({ children }) => (
									<Grid item>
										<Typography variant='body1'>
											<li>{children}</li>
										</Typography>
									</Grid>
								),
							}}
						/>
					</Grid>

					<Button
						onClick={(e) => navigate(link)}
						variant='contained'
						color='secondary'
						className={classes.button}
						style={{
							background: templates
								? industry.accentColor[0]?.hexValue
								: '#3FA529',
						}}>
						Learn More
					</Button>
				</CardContent>
			</Card>
		</Grid>
	);
};
